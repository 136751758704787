import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { colors } from "../../theme/Colors";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import sendyo from "../../imgs/sendyo-logo.svg";
import { useLogin } from "../../hooks/useLogin";
import { MuiOtpInput } from "mui-one-time-password-input";
import MzErrorText from "../../components/ui/MzErrorText";
import Api from "../../api/Api";

import { Urls } from "../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import styled from "@emotion/styled";

function EnterOtpResetPassword() {
  const navigate = useNavigate();
  // const [optSent, setOtpSent] = useState(false);
  const [otpTimeOut, setOtpTimeOut] = useState(false);

  const location = useLocation();

  const validationSchema = yup.object().shape({
    email: yup.string().email("entrer un email valide").required("Ce champ est obligatoire"),
    otp: yup.string().required("Ce champ est obligatoire"),
    // otp: yup.string().min(6, "valeur invalide").required("Ce champ est obligatoire"),
    password: yup.string().required("Ce champ est obligatoire"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], "le mot de passe doit correspondre"),
  });

  const initialValues = {
    email: location.state,
    otp: "",
    password: "",
    confirmPassword: "",
  };

  const onSubmitResetPassword = (values) => {
    Api.post(
      Urls.RESET_PASSWORD,
      {
        email: location.state,
        otp: values?.otp,
        password: values?.password,
        password_confirmation: values.confirmPassword,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate("/login");
        }, 6000);
        // setOtpSent(true);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: `${colors.blue}` }} display="flex" alignItems="center" justifyContent="center">
      <ToastContainer />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitResetPassword}>
        {/* <Form>
          <Box sx={{ boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.65)", padding: "2rem", background: "white", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "0rem" }}>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
              <img src={sendyo} width={100} alt="logo" />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
              <Box maxWidth="350px" display="flex" flexDirection="column" gap="5px">
                <label>Code otp</label>
                <MuiOtpInput length={6} value={formikotp.values.otp} onChange={(value) => formikotp.setFieldValue("otp", value)} />
                {formikotp.errors.otp && formikotp.touched.otp && <MzErrorText>{formikotp.errors.otp}</MzErrorText>}
              </Box>
            </Box>
            <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button onClick={handleClickVerifyOtp} type="submit" disabled={isLoading || formikotp.values.otp?.length < 6} variant="primary">
                Changer le mot de passe
              </Button>
            </Box>
          </Box>
        </Form> */}

        {({ values, setFieldValue, errors }) => (
          <Form>
            {console.log(location.state)}
            <Box sx={{ boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.65)", padding: "2rem", background: "white", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "0rem" }}>
              <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                <img src={sendyo} width={100} alt="logo" />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
                <Box maxWidth="350px" display="flex" flexDirection="column" gap="5px">
                  <label>Code otp</label>
                  <MuiOtpInput length={6} value={values.otp} onChange={(value) => setFieldValue("otp", value)} />
                  <MzErrorText>{errors.otp}</MzErrorText>
                </Box>

                <Box width="100%" maxWidth="700px" display="flex" flexDirection="column">
                  <label>Mot de passe</label>
                  <Field name="password" as={TextField} type="password" />
                  <ErrorMessage name="password" component={MzErrorText} />
                </Box>

                <Box width="100%" maxWidth="700px" display="flex" flexDirection="column">
                  <label>Confirmer mot de passe</label>
                  <Field name="confirmPassword" as={TextField} type="password" />
                  <ErrorMessage name="confirmPassword" component={MzErrorText} />
                </Box>
              </Box>

              <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button type="submit" variant="primary">
                  Changer le mot de passe
                </Button>
              </Box>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </Box>
          </Form>
        )}
      </Formik>

      {/* <Typography> hello</Typography> */}
      {/* <pre>{location}</pre> */}
    </Box>
  );
}

export default EnterOtpResetPassword;
