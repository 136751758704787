export const Urls = {
  //auth
  LOGIN_URL: "/auth/login",

  //logout
  LOGOUT: "/auth/logout",

  //LOGIN EMAIL OTP
  LOGIN_URL_OTP: "/auth/login-with-otp",

  //get list of tags
  GET_TAG_LIST: "/tags-with-filter",

  //get category list
  GET_CATEGORIES: "/categories",

  //create a CATEGORY
  CREATE_CATEGORY: "/categories",

  //delete CATEGORY
  DELETE_CATEGORY: "/categories/",

  // update category
  UPDATE_CATEGORY: "/categories/",

  //create a tag
  CREATE_TAG: "/tags",

  //delete tag
  DELETE_TAG: "/tags/",

  // update tag
  UPDATE_TAG: "/tags/",

  //get articles
  GET_ARTICLES: "/posts-with-filter",

  //get tags for select
  GET_TAGS: "/tags",

  //creer un article
  CREATE_ARTICLE: "/posts",

  //delete article
  DELETE_ARTICLE: "/posts/",

  //get article by id

  GET_ARTCIEL_BY_ID: "/post-by-id/",

  //update article pic

  UPDATE_PIC: "/posts/",

  //update article
  UPDATE_ARTICLE: "/posts/",

  //update user password

  UPDATE_USER_PASSWORD: "/users/",

  //reset password
  PASSWORD_RESET_OTP: "/auth/send-reset-password-otp",

  //reset password
  RESET_PASSWORD: "/auth/reset-password",
};
