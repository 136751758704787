import { GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { colors } from "../../theme/Colors";

export function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ "& .MuiButton-root": { color: colors.blue } }}>
      <GridToolbarDensitySelector sx={{ "& .MuiButton-startIcon": { color: colors.blue } }} />
    </GridToolbarContainer>
  );
}
