import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { colors } from "../../theme/Colors";

const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    // backgroundColor: alpha(theme.palette.primary.main, 0.1),
    backgroundColor: alpha(colors.blue, 0.15),
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(colors.blue, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(colors.blue, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(colors.blue, 0.5 + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(colors.blue, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));
