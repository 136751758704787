import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Typography, TextField, Autocomplete, Modal, IconButton } from "@mui/material";
import { Field, ErrorMessage, Formik, Form } from "formik";
import MzErrorText from "../../components/ui/MzErrorText";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import LinearProgress from "@mui/material/LinearProgress";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../theme/Colors";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyle } from "../../theme/ModalStyle";
import * as yup from "yup";
import { ClosedCaptionOff, ErrorSharp } from "@mui/icons-material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { frFR } from "@mui/x-data-grid";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useNavigate } from "react-router-dom";
import { width } from "@mui/system";

function Categories() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const formRef = useRef();
  //form filter initial values
  const initialValuesADD = {
    nom: "",
  };
  const [initialValuesEdit, setIntialEdit] = useState({
    nom: "",
  });
  const validationSchmaADD = yup.object().shape({
    nom: yup.string().required("Ce champs est obligatore"),
  });
  const validationSchmaEdit = yup.object().shape({
    nom: yup.string().required("Ce champs est obligatore"),
  });
  //state modal
  const [openEdit, setOpenEdit] = useState(false);
  const [opencreate, setOpenCreate] = useState(false);
  const [openDELETE, setOpenDelete] = useState(false);

  //useEffect state change
  const [change, setChange] = useState(false);
  const [page, setPage] = useState(0);
  const pageNumbers = [2, 25, 50, 100];
  const [perPage, setPerPage] = useState(pageNumbers[1]);

  //loading datagrid
  const [loading, setIsLoading] = useState(true);
  //setting the datagrid columns
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      headerClassName: "grid-header",
    },
    {
      field: "name",
      headerName: "Nom",
      description: "Nom",
      width: 150,
      align: "left",
    },
    {
      field: "created_at",
      headerName: "Créé le",
      description: "date de création de la catégorie",
      width: 150,
      renderCell: (params) => {
        return params.row.created_at?.split("T")[0];
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "Action sur la ligne.",
      align: "center",
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
            <EditIcon
              onClick={() => {
                setOpenEdit(true);
                setID(params.row.id);
                setIntialEdit({
                  nom: params.row.name,
                });
              }}
              sx={{ color: `${colors.blue}`, cursor: "pointer" }}
            />
            <DeleteIcon
              onClick={() => {
                setOpenDelete(true);
                setID(params.row.id);
              }}
              sx={{ color: "darkred", cursor: "pointer" }}
            />
          </Box>
        );
      },
      width: 150,
    },
  ];
  //setting tags
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [id, setID] = useState(null);
  const [row, setRow] = useState(null);

  useEffect(() => {
    Api.get(Urls.GET_CATEGORIES + `?perPage=${perPage}&page=${page + 1}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
      params: {
        ...(formRef?.current?.values?.name && { name: formRef?.current?.values?.name }),
      },
    })
      .then((res) => {
        //console.log(res);
        setRows(res.data.data);
        setData(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [page, perPage, change]);

  //search
  const onSubmitSearchCategories = (values) => {
    // //console.log("VALUES", values);
    // //console.log("REAL VALUES", realValues);
    // //console.log("at from submit", access_token);

    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_CATEGORIES + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: {
        ...(values?.name && { name: values?.name }),
      },
    })
      .then((response) => {
        // //console.log("response", response);
        setIsLoading(false);
        setRows(response.data.data);
        setData(response.data);
      })
      .catch((error) => {
        // //console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //navigate
  const navigate = useNavigate();
  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }
  //create tag
  const createCategory = (values) => {
    Api.post(
      Urls.CREATE_CATEGORY,
      {
        name: values.nom,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": "en||fr", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        setOpenCreate(false);
        toast.success("Catégorie créé avec success");
        //console.log(res);
        setChange(!change);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //delete
  const deleteCategory = () => {
    Api.delete(Urls.DELETE_CATEGORY + id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res);
        setOpenDelete(false);
        setChange(!change);
        toast.success("Catégorie supprimée avec success");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //modify tag
  const modifyCateory = (values) => {
    Api.patch(
      Urls.UPDATE_CATEGORY + id,
      {
        name: values.nom,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenEdit(false);
        setChange(!change);
        setRow(null);
        toast.success("Catégorie modifié avec success");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <Box>
      <Box marginBottom="2rem">
        <Typography variant="title-page">Gestion des catégories</Typography>
      </Box>
      <Box marginBottom="2rem">
        <Button onClick={() => setOpenCreate(true)} variant="primary">
          Créer une catégorie
        </Button>
      </Box>
      <Box sx={{ marginBottom: "2rem" }}>
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
          }}
          onSubmit={(values) => {
            onSubmitSearchCategories(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" alignItems="center" gap="1rem">
                <Typography sx={{ fontWeight: "600" }}>Filtres :</Typography>
                <Box display="flex" gap="5px" alignItems="end">
                  <Box>
                    <Field type="input" name="name" as={TextField} label="Nom" id="Nom" size="small" />
                    <ErrorMessage name="name" as={MzErrorText} />
                  </Box>
                  <IconButton
                    onClick={() => {
                      setFieldValue("name", "");
                      onSubmitSearchCategories();
                    }}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Box>
                <Box>
                  <Button type="submit" variant="primary">
                    Chercher
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          // sx={{ maxWidth: "1180px" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on

          // onSelectionModelChange={(ids) => {
          //   const selectedIDs = new Set(ids);
          //   const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          //   setSelectedRows(selectedRows);
          // }}
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // //console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          onPageChange={(newPage) => {
            // //console.log(newPage);
            setPage(newPage);
          }}
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rows}
          //allow selection
          // checkboxSelection
          //how many rows in a page ?
          rowCount={data?.meta?.total}
          //mode
          paginationMode="server"
          //total number of rows per search
          //onpage change
          pagination
        />
        {/* <pre>{JSON.stringify(selectedRows, null, 2)}</pre>*/}
      </Box>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        <Box sx={modalStyle}>
          <Box marginBottom="2rem" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: `${colors.blue}`, padding: "1rem", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
            <Typography fontWeight="600" color={colors.black} textAlign="center">
              Modifier une catégorie
            </Typography>
          </Box>
          <Formik
            initialValues={initialValuesEdit}
            onSubmit={(values) => {
              modifyCateory(values);
            }}
            validationSchema={validationSchmaEdit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Box margin="0rem 2rem 1.5rem 2rem" display="flex" flexDirection="column" alignItems="center" gap="1rem">
                  <Box display="flex" flexDirection="column" gap="0.5rem">
                    <label>Nom</label>
                    <Field type="input" name="nom" as={TextField} label="Nom" id="Nom" size="small" />
                    {errors.nom && touched.nom && <MzErrorText>{errors.nom}</MzErrorText>}
                  </Box>
                </Box>
                <Box margin="0rem 1rem 1rem 1rem" display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
                  <Button type="submit" variant="primary">
                    Modifier
                  </Button>
                  <Button onClick={() => setOpenEdit(false)} variant="black">
                    Annuler
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal open={opencreate} onClose={() => setOpenCreate(false)}>
        <Box sx={modalStyle}>
          <Box marginBottom="2rem" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: `${colors.blue}`, padding: "1rem", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
            <Typography fontWeight="600" color={colors.black} textAlign="center">
              Créer une catégorie
            </Typography>
          </Box>
          <Formik
            initialValues={initialValuesADD}
            onSubmit={(values) => {
              createCategory(values);
            }}
            validationSchema={validationSchmaADD}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Box margin="0rem 2rem 1.5rem 2rem" display="flex" flexDirection="column" alignItems="center" gap="1rem">
                  <Box display="flex" flexDirection="column" gap="0.5rem">
                    <label>Nom</label>
                    <Field type="input" name="nom" as={TextField} label="Nom" id="Nom" size="small" />
                    {errors.nom && touched.nom && <MzErrorText>{errors.nom}</MzErrorText>}
                  </Box>
                </Box>
                <Box margin="0rem 1rem 1rem 1rem" display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
                  <Button type="submit" variant="primary">
                    Confirmer
                  </Button>
                  <Button onClick={() => setOpenCreate(false)} variant="black">
                    Annuler
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal open={openDELETE} onClose={() => setOpenDelete(false)}>
        <Box sx={modalStyle}>
          <Box marginBottom="2rem" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: `red`, padding: "1rem", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
            <Typography fontWeight="600" color={colors.black} textAlign="center">
              Supprimer une categorie
            </Typography>
          </Box>
          <Box padding="0rem 2rem" marginBottom="1rem">
            <Typography textAlign="center">Voulez vous vraiment supprimer cette catégorie ?</Typography>
          </Box>
          <Box margin="0rem 1rem 1rem 1rem" display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
            <Button onClick={() => deleteCategory()} variant="primary">
              Confirmer
            </Button>
            <Button onClick={() => setOpenDelete(false)} variant="black">
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Box>
  );
}

export default Categories;
