import React, { useRef, useState, useEffect, useMemo } from "react";
import { Box, Typography, Button, TextField, Autocomplete, Avatar, IconButton, FormGroup, FormControlLabel, Checkbox, Alert, CircularProgress } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { colors } from "../../theme/Colors";
import MzErrorText from "../../components/ui/MzErrorText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import * as yup from "yup";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";

function DetailsArticle() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const { id } = useParams();
  const formRef = useRef();
  const [image, setImage] = useState(null);
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  //jodit editor params
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [contentENG, setContentENG] = useState("");
  //File config
  const FILE_SIZE = 5000 * 1024;
  const [initialVal, setIntialVal] = useState({
    nom_fr: "",
    en_vedette: false,
    Visible: false,
    categorie: "",
    text_fr: "",
    tags: [],
  });
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
  //validation schema article
  const validationSchema = yup.object().shape({
    nom_fr: yup.string().required("ce champ est obligatoire"),
    text_fr: yup.string().required("ce champ est obligatoire"),
    tags: yup.array().min(1, "entrer au moin un tag"),
    categorie: yup.object({
      name: yup.string().required("ce champ est obligatoire"),
    }),
  });
  const validationSchemaPic = yup.object().shape({
    image: yup
      .mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_TAGS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setTags(res.data.tags);
        //console.log(array, "array tags");
        setOptions(res.data.tags);
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_CATEGORIES, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        //console.log(error);
        toast.error(error?.response?.data?.message);
      });
  }, []);
  //state succes error
  const [succes, setSucces] = useState("");
  const [error, setError] = useState("");
  const [searchedTag, setSearchedTag] = useState("");
  const [searchedCategorie, setSearchedCategorie] = useState("");

  //search tags
  const searchTags = (text) => {
    Api.get(Urls.GET_TAGS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(text && { name: text }),
      },
    })
      .then((res) => {
        setTags(res.data.tags);
        let array = [];
        if (res.data.tags?.length === 0) {
          array.push({ name: text, new: true });
          setOptions(array);
        } else {
          setOptions(res?.data?.tags);
        }
        //console.log(array, "array tags");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const getTags = (text) => {
    Api.get(Urls.GET_TAGS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(text && { name: text }),
      },
    })
      .then((res) => {
        setTags(res.data.tags);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //search categorie
  const searchCategory = (text) => {
    Api.get(Urls.GET_CATEGORIES, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(text && { name: text }),
      },
    })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const debouncedSearch = React.useMemo(() => {
    return debounce((searchedTag) => {
      searchTags(searchedTag);
    }, 100);
  }, []);
  useEffect(() => {
    debouncedSearch(searchedTag);
  }, [searchedTag]);
  const debouncedSearchCat = React.useMemo(() => {
    return debounce((searchedCategorie) => {
      searchCategory(searchedCategorie);
    }, 300);
  }, []);
  useEffect(() => {
    debouncedSearchCat(searchedCategorie);
  }, [searchedCategorie]);
  const createTag = (name) => {
    return new Promise((resolve, reject) => {
      Api.post(
        Urls.CREATE_TAG,
        {
          name: name,
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": "en||fr", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          toast.error(error?.response?.data?.message);
        });
    });
  };
  //create article
  const updateArticle = async (values) => {
    setLoading(true);
    let ids = [];
    let array = [];
    let notfound = [];
    const tagCreationPromises = [];
    let newElements = values?.tags?.filter((t) => t?.new === true);
    console.log(newElements, "new elements");
    //console.log(values);
    for (let index = 0; index < values?.tags?.length; index++) {
      const element = values?.tags[index];
      if (element?.new) {
        tagCreationPromises.push(createTag(element?.name));
      }
    }
    await Promise.all(tagCreationPromises);
    let data = {};
    Api.get(Urls?.GET_TAGS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    }).then((res) => {
      data = res?.data;
      array = res?.data.tags;
      for (let index = 0; index < newElements.length; index++) {
        let found = {};
        const element = newElements[index];
        found = array?.filter((a) => a?.name === element?.name);
        console.log(found, "found element");
        if (found[0]) {
          ids.push(found[0]?.id);
        } else {
          notfound.push(element);
        }
      }
      let notfoundExtended = [];
      if (notfound?.length > 0) {
        Api.get(Urls?.GET_TAGS + "?page=" + data?.meta?.last_page, {
          headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        }).then((res) => {
          array = res?.data.tags;
          for (let index = 0; index < newElements.length; index++) {
            let found = {};
            const element = newElements[index];
            found = array?.filter((a) => a?.name === element?.name);
            console.log(found, "found element");
            if (found[0]) {
              ids.push(found[0]?.id);
            } else {
              notfoundExtended.push(element);
            }
          }
          if (notfoundExtended?.length > 0) {
            Api.get(Urls?.GET_TAGS + "?page=" + data?.meta?.last_page - 1, {
              headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
            }).then((res) => {
              array = res?.data.tags;
              for (let index = 0; index < newElements.length; index++) {
                let found = {};
                const element = newElements[index];
                found = array?.filter((a) => a?.name === element?.name);
                console.log(found, "found element");
                if (found[0]) {
                  ids.push(found[0]?.id);
                }
              }
              console.log(values?.tags, "tags from values original");
              for (let index = 0; index < values?.tags.length; index++) {
                const element = values?.tags[index];
                if (element?.id) {
                  ids.push(element?.id);
                }
              }
              console.log(ids, "tags ids from new elements");
              Api.patch(
                Urls.UPDATE_ARTICLE + id,
                {
                  title: values.nom_fr,
                  text: "<div style='font-size : 25px; color: #676767'>" + values.text_fr + "</div>",
                  image: values.image,
                  en_vedette: values.en_vedette ? 1 : 0,
                  visible: values.Visible ? 1 : 0,
                  tags: ids,
                  category_id: values?.categorie?.id,
                },
                {
                  headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
                }
              )
                .then((res) => {
                  //console.log(res);
                  toast.success("Article modifié avec succés");
                  navigate("/admin/gestion-des-articles");

                  formRef.current.values = formRef.current.initialValues;
                })
                .catch((error) => {
                  //console.log(error);
                  formRef.current.values = formRef.current.initialValues;
                  toast.error(error?.response?.data?.message);
                });
            });
          } else {
            console.log(values?.tags, "tags from values original");
            for (let index = 0; index < values?.tags.length; index++) {
              const element = values?.tags[index];
              if (element?.id) {
                ids.push(element?.id);
              }
            }
            console.log(ids, "tags ids from new elements");
            Api.patch(
              Urls.UPDATE_ARTICLE + id,
              {
                title: values.nom_fr,
                tags: ids,
                text: "<div style:'font-size : 25px; color: #676767'>" + values.text_fr + "</div>",
                image: values.image,
                en_vedette: values.en_vedette ? 1 : 0,
                visible: values.Visible ? 1 : 0,
                category_id: values?.categorie?.id,
              },
              {
                headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
              }
            )
              .then((res) => {
                //console.log(res);
                toast.success("Article modifié avec succés");
                navigate("/admin/gestion-des-articles");

                formRef.current.values = formRef.current.initialValues;
              })
              .catch((error) => {
                //console.log(error);
                formRef.current.values = formRef.current.initialValues;
                toast.error(error?.response?.data?.message);
              });
          }
        });
      } else {
        console.log(values?.tags, "tags from values original");
        for (let index = 0; index < values?.tags.length; index++) {
          const element = values?.tags[index];
          if (element?.id) {
            ids.push(element?.id);
          }
        }
        console.log(ids, "tags ids from new elements");
        Api.patch(
          Urls.UPDATE_ARTICLE + id,
          {
            title: values.nom_fr,
            tags: ids,
            text: "<div style:'font-size : 25px; color: #676767'>" + values.text_fr + "</div>",
            image: values.image,
            en_vedette: values.en_vedette ? 1 : 0,
            visible: values.Visible ? 1 : 0,
            category_id: values?.categorie?.id,
          },
          {
            headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
          }
        )
          .then((res) => {
            //console.log(res);
            toast.success("Article modifié avec succés");
            navigate("/admin/gestion-des-articles");
            formRef.current.values = formRef.current.initialValues;
          })
          .catch((error) => {
            //console.log(error);
            formRef.current.values = formRef.current.initialValues;
            toast.error(error?.response?.data?.message);
          });
      }
    });
    setLoading(false);
  };
  const configFr = useMemo(() => {
    return {
      height: "400px",
      language: "fr",
      maxWidth: "80%",
      style: {
        color: "#676767",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "25px",
      },
      readonly: false,
      placeholder: "Contenu de l'article en francais ...",
      buttons: ["source", "|", "bold", "strikethrough", "underline", "italic", "|", "ul", "ol", "|", "outdent", "indent", "|", "brush", "paragraph", "|", "image", "video", "table", "link", "|", "align", "undo", "redo", "|", "hr", "eraser", "copyformat", "|", "symbol", "fullsize", "print", "about"],
    };
  }, []);
  const [change, setChange] = useState(false);
  useEffect(() => {
    Api.get(Urls.GET_ARTCIEL_BY_ID + id, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: { lang: "fr" },
    })
      .then((res) => {
        let array = [];
        //console.log(res);
        formRef.current.values.image = res?.data?.post?.image_url;
        setImage(res?.data?.post?.image_url);
        setContent(res?.data?.post?.text);
        setIntialVal({
          nom_fr: res?.data?.post?.title,
          text_fr: res?.data?.post?.text,
          Visible: res?.data?.post?.visible === 0 ? false : true,
          en_vedette: res?.data?.post?.en_vedette === 0 ? false : true,
          categorie: res?.data?.post?.category,
          tags: res?.data?.post?.tags,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, [change]);
  const updatePic = (values) => {
    //console.log(values.image);
    Api.post(
      Urls.UPDATE_PIC + id + "/image",
      {
        image: values.image,
      },
      {
        headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        setChange(!change);
        toast.success("Photo modifié avec succés");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // ////console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  return (
    <Box>
      <Box marginBottom="2rem">
        <Typography variant="title-page">Modifier un article</Typography>
      </Box>
      {error && (
        <Alert sx={{ margin: "1rem 0rem" }} severity="error">
          {error}
        </Alert>
      )}
      {succes && (
        <Alert sx={{ margin: "1rem 0rem" }} severity="success">
          {succes}
        </Alert>
      )}

      <Box sx={{ marginBottom: "2rem" }}>
        <Formik
          innerRef={formRef}
          initialValues={{
            image: "",
          }}
          validationSchema={validationSchemaPic}
          onSubmit={(values) => {
            updatePic(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Box display="Flex" gap="24px" alignItems="center">
                <Box display="flex" flexDirection="column" gap="24px">
                  <label style={{ color: `${colors.blue_night}` }}>- Image de l'article</label>
                  <Avatar
                    sx={{
                      position: "relative",
                      borderRadius: "20px",
                      width: { xs: "300px", lg: "400px", md: "400px", sm: "300px" },
                      height: "226px",
                      border: "1px solid #C9C5D2",
                      marginBottom: "1rem",
                    }}
                    variant="rounded"
                  >
                    {image ? <img src={image} style={{ height: "100%", width: "100%" }} alt="photo article" /> : null}
                    <IconButton
                      sx={{
                        zIndex: "33",
                        position: "absolute",
                        right: "0.5rem",
                        bottom: "0.5rem",
                        bgcolor: `${colors.blue}`,
                        "&:hover": { bgcolor: `${colors.blue_night}` },
                      }}
                      component="label"
                    >
                      <input
                        hidden
                        id="image"
                        name="image"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0] ? event.target.files[0] : null);
                          setImage(URL.createObjectURL(event.target.files[0]));
                        }}
                      />
                      <AddRoundedIcon htmlColor="white" />
                    </IconButton>
                  </Avatar>
                  {errors.image && touched.image && <MzErrorText>{errors.image}</MzErrorText>}
                </Box>
                <Button type="submit" variant="primary">
                  Enregistrer
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <Formik
          innerRef={formRef}
          initialValues={initialVal}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            updateArticle(values);
          }}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} maxWidth="80%">
                  <Box flex={1} display="flex" flexDirection="column" gap="0.5rem">
                    <label style={{ color: `${colors.blue_night}` }}>- Titre :</label>
                    <Field type="input" name="nom_fr" as={TextField} label="Titre en francais" id="Nom" size="small" />
                    {errors.nom_fr && touched.nom_fr && <MzErrorText>{errors.nom_fr}</MzErrorText>}
                  </Box>

                  <Box flex={1} display="flex" flexDirection="column" gap="0.5rem">
                    <label style={{ color: `${colors.blue_night}` }}>- Catégorie de l'article : </label>
                    <Field
                      multiple={false}
                      name="categorie"
                      value={values.categorie}
                      component={Autocomplete}
                      options={categories}
                      isOptionEqualToValue={(option, value) => option?.name === value?.name}
                      getOptionLabel={(s) => s?.name}
                      onChange={(e, value) => {
                        setFieldValue("categorie", value ? value : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => setSearchedCategorie(e.target.value)}
                          sx={{ minWidth: "300px" }}
                          {...params}
                          name="autocomplete"
                          label="Catégorie"
                        />
                      )}
                      size="small"
                    />
                    {errors.categorie && touched.categorie && <MzErrorText>{errors.categorie}</MzErrorText>}
                  </Box>
                </Box>
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <FormControlLabel control={<Checkbox checked={values.en_vedette} onChange={(e) => setFieldValue("en_vedette", e.target.checked)} />} label="En vedette" />
                  <FormControlLabel control={<Checkbox checked={values.Visible} onChange={(e) => setFieldValue("Visible", e.target.checked)} />} label="Visible" />
                </Box>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Contenu de l'article :</label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    style={{
                      color: "red",
                    }}
                    config={configFr}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => {
                      setFieldValue("text_fr", newContent);
                      setContent(newContent);
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  {errors.text_fr && <MzErrorText>{errors.text_fr}</MzErrorText>}
                </Box>
                <Box maxWidth="80%" display="flex" flexDirection="column" gap="0.5rem">
                  <label style={{ color: `${colors.blue_night}` }}>- Tags en relation avec l'article : </label>

                  <Field
                    multiple={true}
                    name="tags"
                    value={values?.tags}
                    component={Autocomplete}
                    options={options}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    getOptionLabel={(s) => s?.name}
                    onChange={(e, value) => {
                      setFieldValue("tags", value ? value : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => setSearchedTag(e.target.value)}
                        sx={{ minWidth: "300px" }}
                        {...params}
                        name="autocomplete"
                        label="Tags"
                      />
                    )}
                    size="small"
                  />
                  {errors.tags && touched.tags && <MzErrorText>{errors.tags}</MzErrorText>}
                </Box>

                <Box margin="2rem 0rem">
                  <Button type="submit" variant="primary">
                    {!loading && "Modifier l'article"}
                    {loading && <CircularProgress sx={{ color: "white", fontSize: "small" }} />}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Box>
  );
}
export default DetailsArticle;
