import React from "react";
import { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Avatar } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MonCompte() {
  ////console.log(process.env);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const id = user.user?.id;
  //console.log(id);
  //initial values

  //validation schema
  const validationSchema = Yup.object({
    ancien_motdepasse: Yup.string().required("Ce champ est obligatoire"),
    nouveau_motdepasse: Yup.string()
      .required("Ce champ est obligatoire")
      .notOneOf([Yup.ref("ancien_motdepasse"), null], "Le nouveau mot de passe doit être différent de l'ancient"),
    confirmer_motdepasse: Yup.string()
      .required("Ce champs est obligatoire")
      .oneOf([Yup.ref("nouveau_motdepasse"), null], "Le mot de passe ne correspond pas"),
  });

  //changer mot de passe
  const updateMotDePasse = (values) => {
    Api.patch(
      Urls.UPDATE_USER_PASSWORD + `${id}/password`,
      {
        old_password: values.ancien_motdepasse,
        new_password: values.nouveau_motdepasse,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success("Mot de passe modifié");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  return (
    <Box maxWidth="800px">
      <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      <Box>
        <Typography sx={{ margin: "2rem 0rem 1rem 0rem", fontWeight: "500" }}>Changer mot de passe du compte</Typography>
      </Box>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ancien_motdepasse: "",
            nouveau_motdepasse: "",
            confirmer_motdepasse: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            ////console.log(values);
            updateMotDePasse(values);
            //onSubmitUpdateEntreprise(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {/* SECTION ENTREPRISE INFOS */}
              <Box>
                <Box marginBottom="1rem">
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field type="password" name="ancien_motdepasse" sx={{ maxWidth: "500px" }} as={TextField} variant="outlined" label="Ancien mot de passe" size="small" />
                    <ErrorMessage name="ancien_motdepasse" component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field type="password" name="nouveau_motdepasse" as={TextField} sx={{ maxWidth: "500px" }} variant="outlined" label="Nouveau mot de passe*" size="small" />
                    <ErrorMessage name="nouveau_motdepasse" component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field type="password" name="confirmer_motdepasse" sx={{ maxWidth: "500px" }} as={TextField} variant="outlined" label="Confirmer nouveau mot de passe*" size="small" />
                    <ErrorMessage name="confirmer_motdepasse" component={MzErrorText} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button type="submit" variant="primary">
                  Changer mot de passe
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {/* Filter  Section */}
      {/* Form Section */}
    </Box>
  );
}

export default MonCompte;
